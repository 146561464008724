<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Abmeldung bearbeiten"
      :actions="actions"
      isPreview
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="updateItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <leave-editor v-model="leave" mode="edit"></leave-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db, Timestamp, auth } from "@/firebase";
import { ADMIN, AVAILABILITY } from "@/store/modules.js";
import { GET_USER_LIST, UPDATE_LEAVE } from "@/store/action-types.js";
import { AVAILABILITY_LEAVES_update } from "@/data/permission-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "availability-leaves-edit",
  components: {
    NavigationBar,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          permission: `${AVAILABILITY_LEAVES_update}`,
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },

      leave: null,
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      //   if (this.permissionToManageLeaves) {
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      //   }

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("leaves")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.leave = doc.data();
            this.leave.duration.start = {
              date: this.convertToDate(this.doc.data().duration.start.date),
              time: this.convertToTime(this.doc.data().duration.start.time),
            };
            this.leave.duration.end = {
              date: this.convertToDate(this.doc.data().duration.end.date),
              time: this.convertToTime(this.doc.data().duration.end.time),
              isOpen: doc.data().duration.end.isOpen,
            };
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    convertToTime(time) {
      time = time.toDate();
      return (
        (time.getHours() < 10 ? "0" : "") +
        time.getHours() +
        ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes()
      );
    },
    updateItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${AVAILABILITY}${UPDATE_LEAVE}`, {
          organizationId: this.$route.params.organizationId,

          duration: {
            start: {
              timestamp: this.convertToTimestamp(
                this.leave.duration.start.date,
                this.leave.duration.start.time
              ),
            },
            end: {
              timestamp: this.convertToTimestamp(
                this.leave.duration.start.date,
                this.leave.duration.start.time
              ),
              isOpen: this.leave.duration.end.isOpen,
            },
          },
          reason: {
            name: this.leave.reason.name,
            details: this.leaves.reason,
          },
          automation: {
            eventResponses: {
              areAutomated: this.leave.automation.eventResponses.areAutomated,
              status: this.leave.automation.eventResponses.status,
              comment: this.leave.automation.eventResponses.comment,
            },
          },
          user: {
            displayName: auth.currentUser.displayName,
            uid: auth.currentUser.uid,
          },
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>
