<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Details"
      :actions="actions"
      isPreview
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="start">
            <v-col cols="12" sm="12" md="6">
              <Subheader icon="mdi-card-text" title="Allgemein"> </Subheader>
              <div class="mt-7">
                <v-row>
                  <v-col cols="12">
                    <v-card>
                      <v-card-title class="subheading mb-n14">
                        <v-spacer></v-spacer>
                        <leave-status-chip
                          :status="leave.status"
                        ></leave-status-chip>
                      </v-card-title>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-badge-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle>{{
                          leave.user.displayName
                        }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-account-question</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle
                          ><leave-reason-chip
                            :reason="leave.reason"
                          ></leave-reason-chip
                        ></v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-clock-start</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle
                          >{{
                            timestampConverter(leave.duration.start.timestamp)
                          }}
                          Uhr
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-clock-end</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle
                          >{{
                            timestampConverter(leave.duration.end.timestamp)
                          }}
                          Uhr
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-percent</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <date-progress-bar
                            :start="leave.duration.start.timestamp"
                            :end="leave.duration.end.timestamp"
                          ></date-progress-bar>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <Subheader icon="mdi-autorenew" title="Automation"> </Subheader>
              <div class="mt-7">
                <v-row>
                  <v-col cols="12">
                    <v-card v-if="leave.automation.eventResponses.areAutomated">
                      <v-card-subtitle
                        >Es werden automatisch für alle Veranstaltungen im
                        angegeben Zeitraum Rückmeldungen mit den folgenden Daten
                        angelegt:</v-card-subtitle
                      >
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-calendar-question</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle>
                          <v-chip
                            color="red lighten-4"
                            class="font-weight-medium red--text text--darken-4"
                            small
                            ><v-icon small left>mdi-close-circle</v-icon>Nicht
                            verfügbar</v-chip
                          ></v-list-item-subtitle
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="leave.automation.eventResponses.comment"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-comment-text</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle
                          >{{ leave.automation.eventResponses.comment }}
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-card>
                    <v-card v-else>
                      <v-card-subtitle
                        >Es werden keine automatischen Rückmeldungen für
                        Veranstaltungen angelegt.</v-card-subtitle
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import DateProgressBar from "@/components/_systemwide/DateProgressBar.vue";
import LeaveStatusChip from "@/components/availability/leaves/LeaveStatusChip.vue";
import LeaveReasonChip from "@/components/availability/leaves/LeaveReasonChip.vue";
import {
  AVAILABILITY_LEAVES_update,
  AVAILABILITY_LEAVES_delete,
} from "@/data/permission-types.js";

export default {
  name: "availability-leaves-details",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    DateProgressBar,
    LeaveStatusChip,
    LeaveReasonChip,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${AVAILABILITY_LEAVES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Aktionen",
          icon: "mdi-dots-horizontal-circle",
          actionStyle: "textBtnMultiple",
          list: [
            {
              title: "Vorzeitig beenden",
              permissions: `${AVAILABILITY_LEAVES_update}`,
              icon: "mdi-check-circle",
              actionStyle: "textBtnSubmit",
              function: this.closeItem,
            },
            {
              title: "Löschen",
              permissions: `${AVAILABILITY_LEAVES_delete}`,
              icon: "mdi-delete",
              actionStyle: "textBtn",
              function: this.deleteItem,
            },
          ],
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      events: [],
      input: null,
      nonce: 0,

      // leave: {
      //   duration: {
      //     start: {
      //       date: "11.07.2022",
      //       time: "00:00",
      //     },
      //     end: {
      //       date: "12.07.2022",
      //       time: "00:00",
      //       isOpen: false,
      //     },
      //   },
      //   reason: {
      //     name: "",
      //     details: "",
      //   },
      //   automation: {
      //     eventResponses: {
      //       areAutomated: true,
      //       status: "2",
      //       comment: "",
      //     },
      //   },
      // },
      leave: null,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "leave", data: this.leave }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("leaves")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.leave = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    comment() {
      const time = new Date().toTimeString();
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
          return ` ${contents
            .split(" ")
            .map((v) => v.charAt(0))
            .join("")}`;
        }),
      });

      this.input = null;
    },
    editItem() {
      this.$router.push({
        name: "availability-leaves-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {},
  },
};
</script>
